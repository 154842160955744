.theme_box {
  .box {
    box-shadow: inset 0px 0px 5px 2px $tropical-green;

    .top {
      text-transform: uppercase;

      span {
        @include rtl-sass-prop(padding-right, padding-left, 5px);
      }

      em {
        font-size: 16px;
      }
    }

    .bottom {
      color: $white;
      margin: 15px 0;
      @include rtl-sass-value(text-align-last, left, right);

      p {
        font-size: $h1-font-size;

        span {
          font-size: $h3-font-size;
        }
      }
    }

    &.active {
      background-color: $tropical-green;
    }
  }
}

@media (max-width: 992px) {
  .theme_box .box .bottom p {
    font-size: 25px;

    span {
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  .theme_box .box {
    .bottom {
      p {
        font-size: 22px;
      }
    }
  }
}
