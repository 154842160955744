.calendar /deep/ {
  display: grid;
  grid-template-columns: 32% 65%;
  column-gap: 3%;

  .left {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-template-rows: auto;
    grid-template-areas: 'calendar_start_date calendar_to calendar_end_date';

    .calendar_start_date {
      grid-area: calendar_start_date;
    }

    .calendar_to {
      grid-area: calendar_to;
    }

    .calendar_end_date {
      grid-area: calendar_end_date;
    }
  }
}

@media (max-width: 550px) {
  .calendar /deep/ {
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}
