$white: #ffffff;
$light-gray: #f5f5f5;
$neutral-gray: #e2e2e2;
$stone-gray: #c6c6c6;
$graphite: #646464;
$black: #000000;
$theme-hue: #1e1e1e;
$yellow1: #ffcd05;
$black1: #282828;
$black2: #121212;
$black3: #424242;

$header-color: #282828;
$teal: #034854;
$red-orange: #e35728;
$light-white: #545051;
$red: #c62400;
$cyan: #00ddce;
$green: #00c79c;
$tropical-green: #147875;
$dark-green: $white;
$sky-blue: #007fff;
$future-blue: #0900ff;
$light-coral: #ff8e5c;
$coral: #e87b6d;
$magenta: #ff3c70;
$dark-magenta: #f44040;

$primary: $theme-hue;
$primary-alt: $yellow1;
$secondary: $cyan;
$secondary-alt: $red;

$theme-colors: ('primary': $primary,
  'primary-alt': $primary-alt,
  '$secondary': $secondary,
  '$secondary-alt': $secondary-alt,
  'teal': $teal,
  'red-orange': $red-orange,
  'red': $red,
  'cyan': $cyan,
  'green': $green,
  'tropical-green': $tropical-green,
  'dark-green': $dark-green,
  'sky-blue': $sky-blue,
  'future-blue': $future-blue,
  'light-coral': $light-coral,
  'coral': $coral,
  'magenta': $magenta,
  'dark-magenta': $dark-magenta,
  'white': $white,
  'light-gray': $light-gray,
  'neutral-gray': $neutral-gray,
  'stone-gray': $stone-gray,
  'graphite': $graphite,
  'black': $black);