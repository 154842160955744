$font-family-sans-serif: 'ProximaNova', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.875rem;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;

$h1-font-size: $font-size-base * 2.8;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$headings-margin-bottom: map-get($spacers, 2);
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;

$font-color-base: $white;
$font-color-muted: rgba($font-color-base, 0.75);
$font-color-disabled: rgba($font-color-base, 0.4);
