.content {
  .select {
    margin-bottom: 10px;

    p {
      line-height: 37px;
      color: $white;
    }
  }

  .table_box {
    margin: 10px 0px;
  }
}

@media (max-width: 768px) {
  .content .table_box {
    margin: 20px 0;
  }
}
