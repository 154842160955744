
              @import "@/assets/css/au.scss";
            

.tree_container {
  flex: 1;
  height: 45px !important;
  background: $black2;
  cursor: pointer;
  /deep/ .el-dropdown-link {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    color: $white;
  }
}
.tree_dropdown {
  padding: 15px;
  box-sizing: border-box;
  /deep/ .el-tree {
    width: 350px;
    height: 300px;
    overflow: scroll;
    .el-tree-node__children {
      overflow: unset;
    }
  }
  .tree_input {
    height: 28px;
    margin-bottom: 10px;
    /deep/ .el-input__inner {
      height: 28px;
      background: #f3f3f3;
      border-radius: 4px;
      border: 0;
      line-height: 28px;
      font-size: 14px;
      color: $black;
      padding-left: 30px;

      @include placeholder {
        color: $stone-gray;
        font-weight: 400;
        font-size: 14px;
      }
    }
    /deep/ .el-icon-search {
      line-height: 28px;
      font-size: 16px;
      color: #3d3d3d;
    }
  }
}
