@import '@/assets/css/components/themeBox.scss';
@import '@/assets/css/components/table.scss';
@import '@/assets/css/components/defaultDatePickerLayout.scss';

.query {
  margin-bottom: 40px;

  .query-container {
    /deep/ .accounts_select {
      border-right: 1px solid $black3;

      // /deep/ .el-input__inner {
      //   border: none !important;
      //   height: 45px !important;
      //   padding: 0 20px;
      //   color: $white;
      //   border-radius: 0;
      // }

      // & /deep/ .el-input .el-select__caret {
      //   color: white !important;

      //   &:before {
      //     content: '\e6e1' !important;
      //   }
      // }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  /deep/.accounts_select {
    width: 200px;
    flex: 1;

    @include screen-mobile1() {
      width: 100%;
      border: 0 !important;
      border-bottom: 1px solid #545051 !important;
    }
  }

  .theme_box {
    padding: 0 160px;

    @include screen-mobile() {
      padding: 0;
    }
  }

  // .query_content_main {
  //   display: grid;
  //   grid-template-columns: 15% auto auto;
  //   grid-template-areas: 'left_box calendar right_box';
  //   margin-bottom: 30px;
  //   column-gap: 3%;

  //   .left_box {
  //     grid-area: left_box;
  //   }

  //   .calendar {
  //     grid-area: calendar;
  //   }

  //   .right_box {
  //     grid-area: right_box;
  //   }

  //   .btn_update_small {
  //     /deep/ span {
  //       font-size: 12px !important;
  //     }
  //   }
  // }
}

.content .table_box {
  // margin: 20px 0;
  margin: 0;

  /deep/.el-table th {
    padding: 0;

    .cell {
      line-height: 60px;
      background-color: $black2;
    }
  }

  /deep/.el-table tr {
    background-color: $theme-hue;

    td {
      padding: 0;

      .cell {
        line-height: 77px;
      }
    }
  }
}

@media (max-width: 768px) {
  .query {
    margin-bottom: 20px;
  }

  .content .table_box {
    // margin: 20px 0;
    margin: 0;
  }
}

//@media (max-width: 1000px) {
.seach_box {
  flex-direction: column;
  .flo {
    margin-bottom: 15px;
  }
  .seach_btn {
    margin-top: 15px;
  }
}
.theme_box {
  padding: 0 !important;
}
