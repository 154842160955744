
              @import "@/assets/css/au.scss";
            

.box-btn {
  height: 134px;
  background-color: $theme-hue;
  border-radius: 10px;
  overflow: hidden;


  margin-top: 20px;
  &.active {
    background: #121212;
  }
  .icon-container {
    height: 134px;
    width: 134px;
    background-color: $black1;
    flex-shrink: 0;
    img {
      width: 60px;
    }
  }
  .word-container {
    flex-direction: column;
    padding: 20px 20px;
    span {
      color: $white;
      font-size: 16px;
      font-weight: 300;
    }
    p {
      color: $white;
      font-size: 20px;
      margin-top: 5px;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
